import { createContext, useEffect, useState } from "react";

export const Context = createContext()

const ContextProvider = (props) => {
    const [dashboardConfig, setDashBoardConfig] = useState(null)
    const [theme, setTheme] = useState(localStorage.getItem('theme'))

    const onSetDashboardConfig = (data) => {
        setDashBoardConfig(data)
    }
    const contextValue = {
        dashboardConfig,
        onSetDashboardConfig,
        theme, setTheme
    }

    useEffect(() => {
        const thm = localStorage.getItem('theme')
        if (thm) {
            console.log('theme in context thm', thm)
            setTheme(thm)
        }
    }, [])


    return (
        <Context.Provider value={contextValue}>
            {props.children}
        </Context.Provider>
    )
}

export default ContextProvider