import React, { useState, useEffect, useRef } from "react";
import UserImage from "../assets/user.png";
import { AiOutlineUserAdd } from "react-icons/ai";
import { IoMdLogOut } from "react-icons/io";
import { useContext } from "react";
import { Context } from "../context/Context";
const ProfileDropdown = ({ email, onLogout }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const contextStore = useContext(Context);
  const dashboardConfig1JSON = contextStore.dashboardConfig;
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    window.addEventListener("click", handleClickOutside);

    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const Avatar = () => (
    <div className="w-[36px] h-[36px] flex items-center justify-center  bg-indigo-700 dark:bg-softPrimary rounded-full">
      <span className="text-white  font-semibold text-sm">
        {dashboardConfig1JSON?.avatarText}
      </span>
    </div>
  );

  return (
    <div className="relative " ref={dropdownRef}>
      {/* Trigger button */}
      <button
        className="flex items-center justify-center w-8 h-8 rounded-full bg-gray-200 text-gray-700 hover:text-gray-900 focus:outline-none"
        onClick={toggleDropdown}
      >
        {/* <i class="fa-regular fa-user"></i> */}
        <img src={UserImage} alt="" height={22} width={22} />
      </button>

      {/* Dropdown menu */}
      {isOpen && (
        <div className="absolute pt-2 right-0 mt-2 w-[320px] bg-white dark:bg-primary dark:border-gray-400 dark:border rounded-md shadow-lg z-10 overflow-hidden">
          <div className="relative">
            <div className="px-4 py-2 h-[60px] items-center  flex gap-3  border-b border-b-gray-300">
              <Avatar />
              <div className="flex flex-col">
                <span className="font-bold text-info dark:text-white">
                  {email}
                </span>
                {/* <span className="text-xs underline text-brand cursor-pointer">
                  View account
                </span> */}
              </div>
            </div>
            <div className="h-[56px] flex justify-between flex-col">
              <button
                onClick={onLogout}
                className=" gap-2 mt-1  items-center text-sm flex rounded-lg px-4 py-2  text-textColor dark:text-white hover:bg-gray-100 dark:hover:bg-softPrimary hover:text-brand w-full text-left"
              >
                <IoMdLogOut size={16} />
                Logout
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfileDropdown;
