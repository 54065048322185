import { createBrowserRouter, Navigate } from 'react-router-dom';
import Analytics from '../pages/Analytics';
import Dashboard from '../Home';
import Login from '../pages/Login';
import Report from '../pages/Report';
import ErrorPage from '../pages/ErrorPage';
const router = createBrowserRouter(
    [
        {
            path: '/',
            element: <Navigate to="/dashboard" replace /> // Redirect to /dashboard
        },
        {
            path: "/dashboard",
            element: <Dashboard />,

            children: [
                {
                    path: '/dashboard/*',
                    element: <Analytics />
                },
                {
                    path: '/dashboard/report',
                    element: <Report />
                },


            ]
        },

        {
            path: '/*',
            element: <ErrorPage />
        },
        {
            path: '/login',
            element: <Login />
        }
    ]
)

export default router