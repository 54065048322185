import './Home.css';
import { RouterProvider } from 'react-router-dom';
import router from './routes/routes';
import ContextProvider from './context/Context';
function App() {




  return (
    <ContextProvider>
      {/* <Dashboard /> */}
      <RouterProvider router={router} />
    </ContextProvider>
  );
}

export default App;
