import React from "react";
import { useNavigate } from "react-router-dom";
const ErrorPage = () => {
  const navigate = useNavigate();
  const onRouteChange = () => {
    navigate("/");
  };
  return (
    <div>
      <div class="flex h-screen flex-col items-center justify-center text-sm text-gray-800">
        <div class="text-7xl font-semibold">404</div>
        <div>The page that you requested cannot be found.</div>
        <div>
          <span class="text-brand-500 cursor-pointer" tabindex="0">
            <span
              href="/"
              className="text-brand underline"
              onClick={onRouteChange}
            >
              Please go to the home page.
            </span>
          </span>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
