/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Outlet } from "react-router-dom";
import Header from "./layouts/Header.jsx";
import Sidebar from "./layouts/Sidebar.jsx";
import { useContext } from "react";
import { Context } from "./context/Context.js";
import { useLocation } from "react-router-dom";
import { useMemo } from "react";

const Dashboard = () => {
  const [open, setOpen] = useState(true);
  const context = useContext(Context);
  const location = useLocation();
  const pathname = location?.pathname;
  const dashboardConfig = context.dashboardConfig;
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("home");
  const [activeDropdown, setActiveDropdown] = useState(null);
  const user = useMemo(() => JSON.parse(localStorage.getItem("lyttl-user")))

  const handleDropdownToggle = (menuItem) => {
    setActiveDropdown(
      activeDropdown === menuItem.menuLabel ? null : menuItem.menuLabel
    );
  };

  const handleMenuToggle = () => {
    setOpen(!open);
    setActiveDropdown(null);
  };
  const handleTabChange = (tab) => {
    setActiveTab(tab);
    // setActiveDropdown(null);
  };
  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const response = await fetch("/config.dashboard.json");
        const dashboardConfigData = await response.json();
        context?.onSetDashboardConfig(dashboardConfigData);
      } catch (error) {
        console.error("Error fetching dashboard config:", error);
      }
    };

    fetchConfig();
  }, []);

  useEffect(() => {
    if (!user?.email || !user?.password) {
      navigate("/login");
      return;
    }

    const defaultDashboard = dashboardConfig?.defaultDashboard?.path;

    const navigationRoute =
      pathname === "/dashboard" ? defaultDashboard : pathname;

    navigate(navigationRoute);
  }, [dashboardConfig]);


  const config = {
    client_id: '12345',
    client_name: 'exampleClient',
    access_level: 1,
    url: 'https://staging.septa.live/process',
    accessTokenUrl: "https://lap.lyttl.in/api/access.php",
    defaultOption: "Chart",
    standardQuestions: [
      "What is the total duration played by each player in Set 1?",
      "Which players received the letter 'C' or 'D' in Set 1?",
      "Count the number of players who have the letter 'L' in Set 1.",
      "List the players and their corresponding skill letters who played in Set 1, sorted by duration in descending order."
    ],
    theme: {
      colorCode: "#5F63F2",
      headerColor: "#5F63F2",
      textColor: "#ffffff",
      backgroundColor: "#ecf0f1",
      inputBorderColor: "#5F63F2",
      buttonSize: "18px",
      borderRadius: "6px",
    },
  };
  const initializeSeptaChatbot = () => {

    // eslint-disable-next-line no-undef
    new SeptaChatbot(config); // Initialize your SeptaChatbox here
  };


  useEffect(() => {
    // Function to load the external script
    const loadSeptaChatboxScript = () => {
      const script = document.createElement('script');
      script.src = 'https://cdn.jsdelivr.net/gh/Dwar-liberin/septa-bot/main-v1.1.6.js'; // Replace with your actual CDN link
      script.async = true;
      script.crossOrigin = "anonymous"; // Add crossorigin attribute

      // Optional: Add event listeners for script load and error
      script.onload = () => {
        console.log('SeptaChatbox script loaded successfully');
        initializeSeptaChatbot(); // Call your initialization function if needed
      };
      script.onerror = () => {
        console.error('Failed to load SeptaChatbox script');
      };

      document.head.appendChild(script); // Append script to the document body
    };

    if (user?.email && user?.password)
      loadSeptaChatboxScript();

    // Cleanup script when component unmounts
    return () => {
      const scriptTag = document.querySelector('#septa');
      if (scriptTag) {
        scriptTag.remove();
      }
    };
  }, []); // Empty dependency array means this runs once on mount and cleanup on unmount


  return (
    <div className="flex w-full">
      <Sidebar
        activeDropdown={activeDropdown}
        activeTab={activeTab}
        handleTabChange={handleTabChange}
        handleMenuToggle={handleMenuToggle}
        handleDropdownToggle={handleDropdownToggle}
        open={open}
      />
      {/* Mian content */}
      <Header setActiveTab={setActiveTab} handleMenuToggle={handleMenuToggle} />
      <div className=" flex-1 mx-2 mt-[56px] px-1">
        <Outlet />
      </div>
    </div >
  );
};

export default Dashboard;
